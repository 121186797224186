<template>
  <layout-provider #default="{ style, isShowBottomNavigation }">
    <div class="default-layout" data-testid="element_000226" :style="style">
      <layout-default-header-bar />
      <div class="header-container" data-testid="element_000303">
        <layout-default-header :class="isShowHeaderBar && 'header-bar'" />
      </div>

      <div
        :class="{
          '--with-bottom-navigation': isShowBottomNavigation,
        }"
        class="content-container"
        data-testid="element_000227"
      >
        <main class="content-slot" :class="isShowHeaderBar && 'header-bar'" data-testid="element_000228">
          <slot />
        </main>

        <div :key="String(isDesktop)" class="footer-container" data-testid="element_000229">
          <template v-if="isDesktop">
            <layout-default-footer-pc-footer />
          </template>
          <template v-if="!isDesktop">
            <lazy-layout-default-footer-mobile />
          </template>
        </div>
      </div>

      <layout-default-left-menu
        :class="{
        leftBlockClasses,
        'header-bar': isShowHeaderBar,
      }" />
    </div>
  </layout-provider>
</template>

<script lang="ts" setup>

const conditionStore = useConditionStore()
const {
  leftBlockClasses,
} = useLayoutDefault()

const { width } = useSSRWindowSize()
const isDesktop = computed(() => {
  return width.value > 599
})

const { layoutIsShowHeaderBar:isShowHeaderBar } = storeToRefs(conditionStore)
</script>
